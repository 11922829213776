import React, { Component } from "react"
import Layout from "../components/Layout/Layout2"
import { graphql } from 'gatsby'


class TermsAndConditions extends Component {
  render() {  

return (
  <Layout>
  	<section className="pt-10 md:pt-20">
  <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-60">
    
      <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Gebruiksvoorwaarden</h1>
	  <p className="text-xl lg:text-2xl mt-6 font-light">
	  Deze pagina beschrijft de gebruiksvoorwaarden van de diensten verleend via deze website.
	  Deze diensten worden niet rechtstreeks verleend via deze website. Het gebruik van het platform en de voorwaarden rond de uitvoering van de diensten zullen in een onderlinge overeenkomst worden beschreven. 
	  </p>

	  <p class="font-semibold text-xl pt-8">AARD VAN DE DIENSTEN</p>
	

	  <p className="text-xl lg:text-2xl mt-6 font-light">
	  De diensten worden verleend via een platform. Via het platform kunnen producten worden verkocht en aangekocht. De voorwaarden rond het gebruik van de diensten en de verkoop zullen in een onderlinge overeenkomst worden beschreven. Wij streven een optimale dienstverlening na en klanttevredenheid. Vraag achter onze referenties.</p>

	  <p class="font-semibold text-xl pt-8">TERUGKEERBELEID</p>
	 
	 <p className="text-xl lg:text-2xl mt-6 font-light">	
	 Producten die niet voldoen aan de kwaliteitsnormen kunnen onder de juiste omstandigheden worden teruggenomen. Deze voorwaarden zullen mee worden gestipuleerd in het contract.
     </p>
	

	<p class="font-semibold text-xl pt-8">
	CONTACTEER ONS
	</p>
	
	<p className="text-xl lg:text-2xl mt-6 font-light">
	  CHATEAU BOHO, Schoenstraat 46-48, 2140 BOrgerHOut-Antwerpen.
	</p> 
	
	<p className="text-xl lg:text-2xl mt-6 font-light">
	  Ondernemingsnummer : 0858304005
	</p> 
	
	<p className="text-xl lg:text-2xl mt-6 font-light">
	  Email : info@chateauboho.be
	</p> 
	
	<p className="text-xl lg:text-2xl mt-6 font-light">
	  Telefoon : +32 474 99 69 59
	</p> 

  </div>
  </div>
  </section>
  </Layout>

)
  }
}
export default TermsAndConditions

